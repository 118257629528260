<template>
  <div class="header-widget" :class="{ fixed: fixed, border: border }">
    <div class="search">
      <div class="logo-box" @click.stop="goPage('index')">
        <img :src="NewkiLogo" width="32" height="32" />
      </div>
      <div class="search-box" @click.stop="goPage('search')">
        <div class="icon">
          <NewkiSvg name="search" :color="primary.bg" :size="22" />
        </div>
        <div class="text-body">商品搜索</div>
      </div>
      <div class="lang" @click.stop="setSite()" v-if="inDisplaySite">
        <NewkiSvg name="earth" :filled="true" :size="24" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, computed, watchEffect } from 'vue'
import NewkiLogo from '~/assets/images/common/newki-logo.svg'
import { useColorsStore } from '~/store/colors'
import { useSystemStore } from '~/store/system'
import { apiSiteQuery, apiAreaCodeQuery, apiLanguageQuery } from '@/service/api/common'
import { useHistory } from '~/store/history'
const { cleanRouterHistory } = useHistory()

const { siteList } = storeToRefs(useSystemStore())

const siteCode = useCookie('newki-site-code').value

// 可顯示站點（frontDisplay === 1）
const siteListDisplay = computed(() => {
  return siteList.value.filter(item => item.frontDisplay != 0)
})

// 當前站點是否在【可顯示站點】的列表中
const inDisplaySite = ref(false)

watchEffect(() => {
  let flag = siteListDisplay.value.filter((item: any) => {
    let code = item.code
    return code === siteCode
  })
  inDisplaySite.value = flag.length > 0
  // console.log('inDisplaySite:', inDisplaySite.value)
})

const props = defineProps({
  fixed: {
    type: Boolean,
    required: false,
    default: true,
  },
  border: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const { primary } = storeToRefs(useColorsStore())

const { t } = useI18n()

const { currentSite, currentLanguage } = storeToRefs(useSystemStore())
const { toggleSiteSelectShow, querySite } = useSystemStore()

async function setSite(show: boolean = true) {
  let data = await querySite()
  if (data.list) {
    toggleSiteSelectShow(show)
  }
}

// 搜索
const goPage = (name: string) => {
  if (name === 'index') {
    routerReplace('index')
    // process.env.NODE_ENV === 'development' ? routerPush('widget') : routerPush('index')
  } else {
    cleanRouterHistory()
    routerPush('search')
  }
}

onMounted(() => {
  // setSite(false)
})
</script>

<style scoped lang="scss">
.header-widget {
  :deep(.nuxt-icon) {
    @include flex();

    svg {
      margin: 0;
    }
  }
}

.header-widget {
  width: 100%;
  height: 55px;
  background-color: $white;
  border-bottom: 1px solid transparent;

  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
  }

  &.border {
    border-bottom: 1px solid rgba(220, 220, 220, 0.6);
  }

  .search {
    @include flex();
    width: 100%;
    height: 100%;
    padding: 0 8px 0 11px;

    .logo-box {
      @include cursor();
      width: 32px;

      img {
        display: block;
        width: 32px;
        height: 32px;
        object-fit: cover;
      }
    }

    .search-box {
      @include flex(row, flex-start, center);
      flex: 1;
      height: 32px;
      margin: 0 10px 0 3px;
      border-radius: 16px;
      border: 1px solid $border-color;
      background-color: $white;

      .icon {
        @include flex();
        width: 32px;
        height: 100%;
        svg {
          color: $primary-bg;
        }
      }

      .text-body {
        color: $placeholder-color;
      }
    }

    .lang {
      @include flex();
      @include cursor();
      width: 32px;
      height: 44px;
    }
  }
}
</style>
