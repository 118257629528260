import { apiGetMemberInfo } from '@/service/api/member'
import { apiAddBatchCart, apiGetCart } from '@/service/api/cart'
import { useLocalStorage } from '@vueuse/core'
import { useCartStore } from '@/store/cart'
import { useSystemStore } from '~/store/system'

export const useSetMemberInfo = async () => {
  const route = useRoute()
  const { updateLoadingState } = useSystemStore()
  // 同步購物車數據
  const localCart = useLocalStorage('local-cart', '')
  const { setCartData } = useCartStore()
  if (localCart.value) {
    const cartData = JSON.parse(localCart.value)
    for (let i = 0; i < cartData.length; i++) {
      cartData[i].status = '1'
      cartData[i].addQty = cartData[i].qty
      delete cartData[i].qty
    }
    const data = await apiAddBatchCart(cartData)
    if (data) {
      setCartData(data)
    }
  } else {
    // 宣傳頁不需要發起 apiGetCart，在組件內實現
    // if (route.name?.includes('promotion-code')) return false

    if (isSSR()) return false

    // 獲取並填充購物車數據
    if (route.name?.includes('cart')) {
      updateLoadingState(true)
    }
    const data = await apiGetCart()
    updateLoadingState(false)
    if (data) {
      setCartData(data)
    }
  }
  localCart.value = ''
  // 獲取會員信息
  apiGetMemberInfo()
}
