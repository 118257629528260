import type { IMember } from '@/types/IMember'
import { routerReplace } from '@/composables/routerReplace'
import { useSystemStore } from '@/store/system'
import { storeToRefs } from 'pinia'
import { connectChatInfo } from '~/service/api/chat'
import { useGetBetterPrice } from '~/store/getBetterPrice'

export const useMemberStore = defineStore('member', () => {
  // 會員信息
  const memberInfo = ref({
    name: '',
    memberId: '',
    payMember: false,
    phone: '',
    levelName: '',
    createTime: '',
    avatar: '',
    memberPayLevelId: '',
    disableDate: '',
    email: '',
  })
  // 區號
  const areaCode = ref(852)
  // 手機號
  const mobile = ref('')
  // 是否同意用戶協議
  const isAgreeAgreement = ref(false)
  // im使用的ID
  const visitorId = ref('')
  // im使用的Code
  const visitorCode = ref('')

  const getMemberFullPhone = computed(() => memberInfo.value.phone.replace(',', ''))

  function setMobile(phone: string) {
    mobile.value = phone
  }

  function setAreaCode(code: number) {
    areaCode.value = +code
  }

  async function setMemberInfo(data: IMember) {
    const { defaultAreaCode } = storeToRefs(useSystemStore())
    const { toggleSiteSelectShow, querySite } = useSystemStore()
    memberInfo.value = { ...data }
    const mobile = data.phone.split(',')
    setMobile(mobile[1])
    setAreaCode(+mobile[0])
    connectIm()
    const change = useLocalStorage('newki-site-change', '')
    if (+mobile[0] !== +defaultAreaCode.value && change.value !== 'true') {
      await querySite()
      toggleSiteSelectShow(true)
    }
    change.value = 'true'
    const fromInviteeCode = useCookie('newki-url-query-ni').value || ''
    useCookie('nbi').value = fromInviteeCode
    const route = useRoute()
    const routeObj = { query: { ...route.query, ni: data.inviteCode }, params: { ...route.params } }
    routerReplace('', routeObj)
  }

  async function connectIm() {
    const { $im }: any = useNuxtApp()
    const params = {
      visitorChannelCode: 'newki',
      visitorChannelName: 'newki',
      visitorChannelType: 2, // 1-FB 2-Newki 3-whatsApp
      visitorNickname: memberInfo.value.name ? memberInfo.value.name : '遊客',
      visitorPic: memberInfo.value.avatar || '',
      visitorPhone: memberInfo.value.phone || '',
      markType: 10,
    }
    const data = await connectChatInfo(memberInfo.value.memberId, params)
    if (data) {
      visitorId.value = data.id
      visitorCode.value = data.visitorCode
      $im.init()
    }
  }

  function setMemberInfoPhone(data: string) {
    memberInfo.value.phone = data
    const mobile = data.split(',')
    setMobile(mobile[1])
    setAreaCode(+mobile[0])
  }

  function setNickname(data: string) {
    memberInfo.value.name = data
  }
  function setEmail(data: string) {
    memberInfo.value.email = data
  }
  function setAvatar(data: string) {
    memberInfo.value.avatar = data
  }

  function setAgreeAgreement(data: boolean) {
    isAgreeAgreement.value = data
  }

  // 獲取vip活動，防止因用戶登錄沒領到，根據後端返回數據手動掉一次
  function getVip() {
    const { setGetMoreFlag, setPageCode } = useGetBetterPrice()
    setGetMoreFlag(true)
    setPageCode('RegisterGetVip')
  }

  function resetMemberInfo() {
    memberInfo.value = {
      name: '',
      email: '',
      memberId: '',
      payMember: false,
      phone: '',
      levelName: '',
      createTime: '',
      avatar: '',
      memberPayLevelId: '',
      disableDate: '',
    }
  }

  return {
    areaCode,
    mobile,
    getMemberFullPhone,
    isAgreeAgreement,
    memberInfo,
    visitorId,
    visitorCode,
    setMobile,
    setAreaCode,
    setMemberInfo,
    resetMemberInfo,
    setMemberInfoPhone,
    setNickname,
    setEmail,
    setAvatar,
    setAgreeAgreement,
  }
})
