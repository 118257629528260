export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, viewport-fit=cover"},{"charset":"utf-8"},{"name":"google","content":"notranslate"},{"name":"apple-mobile-web-app-capable","content":"yes"},{"name":"facebook-domain-verification","content":"5th8yhuvj3oysch2c39co4hbvndvt7"},{"name":"author","content":"ftofs"},{"name":"ogImage","content":"https://img.twant.com/icon/icon-72@2x.png"}],"link":[{"rel":"apple-touch-startup-image","href":"https://img.twant.com/icon/icon-72@2x.png"},{"rel":"apple-touch-icon","href":"https://img.twant.com/icon/icon-57.png"},{"rel":"apple-touch-icon","sizes":"152x152","href":"https://img.twant.com/icon/icon-72.png"},{"rel":"apple-touch-icon","sizes":"180x180","href":"https://img.twant.com/icon/icon-57@2x.png"},{"rel":"apple-touch-icon","sizes":"167x167","href":"https://img.twant.com/icon/icon-72@2x.png"},{"rel":"apple-touch-icon-precomposed","type":"image/png","href":"https://img.twant.com/icon/icon-72@2x.png"}],"style":[],"script":[],"noscript":[],"charset":"utf-8","viewport":"width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, viewport-fit=cover","title":"新奇站-每月家D新品未？！"}

export const appRootId = "app"

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#app'