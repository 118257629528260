import { $axios } from '../index'

/**
 * @description: 獲取app配置
 */
export const apiGetAppConfig = async () => {
  return $axios()
    .get(':support/app/site/config')
    .then((res: any) => {
      if (res.success) {
        return res.data
      }
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 站點匹配
 */
export const apiMatchSite = (data: any = {}) => {
  return $axios()
    .get(`:mall/app/user/matchSite`, { params: data })
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 站點列表查詢
 */
export const apiSiteQuery = (timestamp: string = String(Date.now())) => {
  return $axios()
    .get(`:support/app/site/query?pageNum=1&pageSize=0&statusList=09&statusList=10&timestamp=${timestamp}`)
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 多語言列表查詢
 */
export const apiLanguageQuery = (siteId: string) => {
  return $axios()
    .get(`:support/app/siteLanguage/query?pageNum=1&pageSize=0&status=09&siteId=${siteId}`)
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 站點區號列表查詢
 */
export const apiAreaCodeQuery = (siteId: string) => {
  return $axios()
    .post(`:support/app/siteAreaCode/query?pageNum=1&pageSize=0`, { siteId })
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 獲取服務器時間
 */
export const apiGetServerTime = () => {
  return $axios()
    .get(`:mall/app/sys/milliSeconds`)
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 文件上傳
 */
export const apiFileUpload = (data: any) => {
  return $axios()
    .post(`:support/app/file/upload`, data)
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 獲取驗證碼
 * @param {number} areaCode  區號
 * @param {string} phone  手機號
 * @param {string} operationType  類型
 */
export const getSmsCode = (areaCode: number, phone: string, operationType: string) => {
  return $axios()
    .post(`:mall/app/user/verifyCode?areaCode=${areaCode}&phone=${phone}`, { operationType })
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}
/**
 * @description: 根據使用場景獲取圖片參數
 * @param {string} scene  圖片使用場景
 */
export const apiGetPicParams = (scene: string) => {
  return $axios()
    .get(`:support/app/pic/parameters/getByScene?scene=${scene}`)
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}
/**
 * @description: 獲取瀏覽記錄
 * @param {string} pageNum  分頁頁數
 * @param {string} keyword  查詢關鍵詞
 */
export const apiGetHistory = (pageNum: string, keyword?: string) => {
  return $axios()
    .post(`:mall/app/skuBrowsingHistory/query?pageNum=${pageNum}&pageSize=10`, { goodsName: keyword })
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}
/**
 * @description: 訂單運費計算
 * @param {}
 */
export const apiGetCalculateFreightAmount = (data: any) => {
  return $axios()
    .post(`:support/app/freightCalculate/calculateOrder`, data)
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 查詢配送方式（快遞方式）
 */
export const apiGetDeliveryWay = () => {
  return $axios()
    .post(`:support/app/deliveryWay/queryList`)
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 獲取頁面檔案內容
 */
export const apiGetPageContnt = (code: string) => {
  return $axios()
    .formPost(`:goods/app/pageConfig/${code}`)
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 模拟 Server 服务端报错
 */
export const mockServer500 = () => {
  return $axios()
    .get(`ycmSupport/app/articleContent/queryList?ids=20435&lang=zh-HK&siteId=911228776782970880`)
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}
