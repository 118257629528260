<template>
  <div class="performace-container" ref="fpsRef" style="position: fixed" @click="close" v-if="show">
    <div class="list">
      <div class="item">FPS：{{ fps }}</div>
      <template v-if="isSupported && memory">
        <div class="item" v-if="isSupported">Memory：{{ size(memory?.usedJSHeapSize) }}</div>
        <!-- <div class="item" v-if="isSupported">Memory Allocated：{{ size(memory?.totalJSHeapSize) }}</div>
        <div class="item" v-if="isSupported">Memory Limit：{{ size(memory?.jsHeapSizeLimit) }}</div> -->
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useMemory } from '@vueuse/core'
import { useFps } from '@vueuse/core'

const show = ref(true)

const fpsRef = ref<HTMLElement | null>(null)
const fps = useFps({
  every: 20,
})
// const { x, y, style } = useDraggable(fpsRef, {
//   initialValue: { x: 10, y: 10 },
// })

const { isSupported, memory } = useMemory()

const size = (v: number) => {
  const kb = v / 1024 / 1024
  return `${kb.toFixed(2)}MB`
}

const close = () => {
  show.value = false
}
</script>

<style scoped lang="scss">
.performace-container {
  @include flex();
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  width: 100%;
  cursor: move;

  .list {
    @include flex();
    gap: 10px;
    width: 220px;
    padding: 0 10px;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.6);
    transform-origin: top;

    .item {
      color: #76de76;
      font-size: 12px;
      transform: scale(calc(10 / 12));
    }
  }
}
</style>
