<template>
  <div class="order-container" @click="jump">
    <div>
      <span class="text">{{ t('orderDetail.order.no') }}：</span><span class="order-no">{{ params.orderNo }}</span>
    </div>
    <div class="order-info-container">
      <NewkiImage class="order-img" :src="params.img" :width="54" :height="54" :radius="6" />
      <div class="order-info">
        <div class="order-info-name">{{ params.name }}</div>
        <div class="order-info-attr">{{ params.attr }}</div>
      </div>
    </div>
  </div>
</template>

<script setup>
const { t } = useI18n()

const props = defineProps({
  params: {
    type: Object,
    required: true,
  },
})

const emit = defineEmits(['close'])

function jump() {
  emit('close')
  routerPush('order-detail-id', { params: { id: props.params.orderId } })
}
</script>

<style scoped lang="scss">
.order-container {
  width: 240px;
  box-sizing: border-box;
  padding: 10px 12px;
  border-radius: 12px;
  background-color: #fff;
  display: flex;
  flex-direction: column;

  .text {
    font-size: 13px;
    color: $text-color-default;
  }
  .order-no {
    font-size: 13px;
    color: $primary-500;
  }
  .order-info-container {
    margin-top: 6px;
    display: flex;

    .order-img {
      width: 54px;
      height: 54px;
      border-radius: 6px;
      margin-right: 10px;
      flex-shrink: 0;
    }
    .order-info {
      padding: 5px 0;
      display: flex;
      flex-direction: column;
      .order-info-name {
        @include line(2);
        font-size: 11px;
        color: #111827;
      }
      .order-info-attr {
        margin-top: 4px;
        font-size: 11px;
        color: $text-color-assist;
      }
    }
  }
}
</style>
